import React from "react"
import Markdown from 'markdown-to-jsx'
import styles from "./scss/index.module.scss"

export default class PrivacyPage extends React.Component {
  render() {
    const props = this.props
    const entries = props.data.allContentfulPrivacyPage.edges

    const [
      { node: { privacyPolicy: { privacyPolicy: policyFI }}},
      { node: { privacyPolicy: { privacyPolicy: policyEN }}},
    ] = entries

    return (
      <div style={{ padding: '50px' }}>
        <div className={styles.frontPage}>
          <div><Markdown>{policyFI}</Markdown></div>
          <div style={{ paddingTop: '50px' }}><Markdown>{policyEN}</Markdown></div>
        </div>
      </div>
    )
  }
}

export const privacyPage = graphql`
  fragment privacyPage on ContentfulPrivacyPage {
    privacyPolicy {
      privacyPolicy
    }
  }
`
