import React from 'react'
import { graphql } from 'gatsby'
import PrivacyPage from '../privacy-page'

export default (props) => <PrivacyPage {...props} />

export const pageQuery = graphql`
  query PrivacyQuery {
    allContentfulPrivacyPage {
      edges {
        node {
          ...privacyPage
        }
      }
    }
  }
`
